import { createElement } from "react";
import Dompurify from "dompurify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useBaseStore, useUserStore } from "./store";
import UAParser from "ua-parser-js";
import { perf, trace } from "~/libs/db/firebase";
import { FirebaseLogger } from "./ga";
import { convert } from "html-to-text";
import { nanoid } from "nanoid";
import { validator } from "~/utils";

// 스타일 Alert 초기화
export const sweetAlert = withReactContent(Swal);

/**
 * Promise safe fn
 *
 * @param {Function} callback 호출함수
 * @param {string} message 오류메시지
 * @param {string} loading 로딩 동작 여부
 * @param {Function} errorCallback 에러 발생 시 호출함수
 * @param {string} perfTitle 퍼포먼스&로깅 측정용 타이틀
 * @param {object} logData 로깅 측정용 데이터 포맷
 */
export async function asyncSafe<T>(
  callback: () => T,
  message: string,
  loading: boolean = true,
  errorCallback?: () => T,
  perfTitle = "",
  logData: any = null
) {
  if (loading) useBaseStore.setState({ isLoading: true });
  try {
    const user = useUserStore.getState()?.user;
    const t = trace(perf, perfTitle || document?.title || "unknown");
    t.putAttribute("region", user?.ip?.region || "unknown");
    t.start();
    await callback();
    t.stop();
  } catch (e) {
    console.log("async fn e: ", e);
    FirebaseLogger("error", {
      event: message ? `${message}` : "Unknown",
      error: JSON.stringify(e) || "Unknown",
      option: callback?.name ? `async ${JSON.stringify(callback)}` : "Unknown",
    });
    if (message) {
      useBaseStore.setState({ notify: { message: `${message} :(` } });
    }
    if (errorCallback) {
      await errorCallback();
    }
  } finally {
    useBaseStore.setState({ isLoading: false });
    if (perfTitle) {
      if (logData) {
        FirebaseLogger("click", { event: perfTitle, ...logData });
      } else {
        FirebaseLogger("click", { event: perfTitle });
      }
    }
  }
}

// pure script src 불러오기 with key
export function loadScriptWithKey(
  id: string,
  url: string,
  callback: Function,
  defer: boolean = false,
  async: boolean = false
) {
  const isScriptExist = document.getElementById(id);
  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.defer = defer;
    script.async = async;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }
  if (isScriptExist && callback) callback();
}

// 빈 값인지 체크
export const isEmpty = (value: any) => {
  if (value === null) return true;
  if (typeof value === "undefined") return true;
  if (typeof value === "string" && value === "") return true;
  if (Array.isArray(value) && value.length < 1) return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "Object" &&
    Object.keys(value).length < 1 // && Object.getOwnPropertyNames(value) < 1
  )
    return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "String" &&
    Object.keys(value).length < 1
  )
    return true;
  return false;
};

/**
 * @ex { "ua": "", "browser": {}, "engine": {}, "os": {}, "device": {}, "cpu": {} }
 * @return 브라우저 정보 반환
 */
export const getBrowserInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return result;
};

// 쿼리스트링 값 뽑기
export const valueFromQueryString = (queryString: string) => {
  let match = null;
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s: string) {
    return decodeURIComponent(s.replace(pl, " "));
  };
  let query = queryString.substring(1);
  let urlParams: any = {};
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }
  return urlParams;
};

// 3자리마다 콤마 붙이기
export const addComma = (x: number) => {
  const ret = Math.round(x);
  return ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// length만큼 문장 자르기 ... 붙이기
export const cutStringAtLength = (
  x: string,
  length: number = 20,
  character: string = "..."
) => {
  if (isEmpty(x)) return "";
  let ret = x;
  if (x.length >= length) ret = `${x.substr(0, length)}${character}`;
  return ret;
};

// HTML 문자열 변환
export const renderHTML = (rawHTML: any, isEditor: boolean = false) => {
  if (!rawHTML) return null;
  if (isEditor) {
    return createElement("div", {
      dangerouslySetInnerHTML: {
        __html: Dompurify.sanitize(rawHTML, {
          ADD_TAGS: ["iframe"],
          ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
        }),
      },
    });
  }

  return createElement("div", {
    dangerouslySetInnerHTML: { __html: Dompurify.sanitize(rawHTML) },
  });
};

// 이메일 주소 받아서 아이디만 추출
export const getEmailPrefixID = (value: string) => {
  if (!value) return "";
  const isEmail = validator.emailValidation(value);
  if (!isEmail) return value.trim();

  return value.split("@")[0].trim();
};

// uuidV4 range만큼 가져오기
export const getUIDwithRange = (range: number = 1): string[] => {
  let ret = [];
  for (let i = 0; i < range; ++i) {
    const uuid = nanoid();
    ret.push(uuid);
  }
  return ret;
};

// PIN 번호 만들기(숫자, 영문, 특수기호 포함)
export const getPinCharacterCode = (length: number = 8): string => {
  // REF: https://aspdotnet.tistory.com/2748
  let ret = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#+-~";
  for (let i = 0; i < length; i++) {
    ret += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return ret;
};

// PIN 번호 만들기(숫자)
export const getPinCode = (length: number = 6): string => {
  // REF: https://aspdotnet.tistory.com/2748
  let ret = "";
  const possible = "0123456789";
  for (let i = 0; i < length; i++) {
    ret += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return ret;
};

const skipHTML = [
  { selector: "a", format: "skip" },
  { selector: "img", format: "skip" },
  { selector: "table", format: "skip" },
];

// html 텍스트로 변경
export const htmlToText = (
  content: string,
  length: number = 100,
  skip: any = null
) => {
  return cutStringAtLength(
    convert(content, {
      wordwrap: length,
      selectors: skip || skipHTML,
    }),
    length
  );
};

/**
 * Runs the function `fn`
 * and retries automatically if it fails.
 * Tries max `1 + retries` times
 * with `retryIntervalMs` milliseconds between retries.
 * From https://mtsknn.fi/blog/js-retry-on-fail/
 * @ex
 * const options = { retries: 2, retryIntervalMs: 200 }
 * retry(async () => { console.log('hi') throw new Error('fail') }, options)
 * => logs 'hi' (initial try)
 * => logs 'hi' (1st retry)
 * => logs 'hi' (2nd retry)
 * => throws [Error: fail]
 */
export const retryFn = async <T>(
  fn: () => Promise<T> | T,
  { retries, retryIntervalMs }: { retries: number; retryIntervalMs: number }
): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (retries <= 0) {
      throw error;
    }
    await sleep(retryIntervalMs);
    return retryFn(fn, { retries: retries - 1, retryIntervalMs });
  }
};

// ms만큼 딜레이
export const sleep = (ms: number = 0) =>
  new Promise((resolve) => setTimeout(resolve, ms));

// FullScreen Window OPEN
export const windowOpenWithFullScreen = (url: string, title: string) => {
  window.open(
    url,
    title,
    `width=${screen.width}, height=${screen.height}, fullscreen=yes`
  );
};
