import { memo, useState, useEffect } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { windowOpenWithFullScreen } from "~/libs/utils";
import IPTooltip from "./IPTooltip";

const IPKakaoBtn = () => {
  const location = useLocation();
  const [state, setState] = useState({ isShow: false });

  useEffect(() => {
    if (location?.pathname) {
      if (
        location.pathname.includes("/space/bookmark") ||
        location.pathname.includes("/space/collection") ||
        location.pathname.includes("/space/board") ||
        location.pathname.includes("/space/evaluate") ||
        location.pathname.includes("/space/user") ||
        location.pathname.includes("/login") ||
        location.pathname.includes("/festival") ||
        location.pathname.includes("/analyze") ||
        location.pathname.includes("/roadmap") ||
        location.pathname.includes("/coach") ||
        location.pathname.includes("/lecture") ||
        location.pathname.includes("/share/slide") ||
        location.pathname.includes("/task") ||
        location.pathname.includes("/verify")
      ) {
        // hide
        setState((prev) => ({ ...prev, isShow: false }));
      } else {
        // show
        setState((prev) => ({ ...prev, isShow: true }));
      }
    }
  }, [location?.pathname]);

  const onHandleClick = () => {
    windowOpenWithFullScreen(
      "https://pf.kakao.com/_xkxoXAn",
      "impactus-kakao-friends"
    );
  };

  if (!state.isShow) return null;

  return (
    <StyledIconButton onClick={onHandleClick}>
      <IPTooltip title="임팩터스 카카오 채널을 추가하고,<br/>궁금한 점을 질문해 주세요!">
        <img
          src="https://developers.kakao.com/tool/resource/static/img/button/channel/add/channel_add_large.png"
          alt="카카오톡 채널 추가하기 버튼"
        />
      </IPTooltip>
    </StyledIconButton>
  );
};

const StyledIconButton = styled(Button)(({ theme }) => ({
  zIndex: 100,
  position: "fixed",
  bottom: 5,
  right: 5,
}));

export default memo(IPKakaoBtn);
