import {
  Toolbar,
  Typography,
  AppBar,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useBaseStore, useUserStore } from "~/libs/store";
import { FirebaseLogger } from "~/libs/ga";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import NavigationDrawer from "./NavigationDrawer";
import Logo from "./Logo";
import Image from "./Image";
import { floatingAnimation } from "~/styles/theme";
import { rocket } from "~/assets";
import { useState } from "react";

/**
 * NOTE: PC 헤더 메뉴
 * 임팩터스 소개
 * 스페이스 소개
 * 스페이스 도입 문의
 * 교육 프로그램 > 커리큘럼, 쇼케이스
 * 유튜브
 * 교육문의
 */

const Header = () => {
  const [state, setState] = useState({
    categories: [
      {
        title: "임팩터스 소개",
        to: "/about",
      },
      {
        title: "스페이스 소개",
        to: "/about/space",
      },
      {
        title: "교육 프로그램",
        to: "/course/program",
      },
      {
        title: "교육 문의",
        to: "/course/form",
      },
      {
        title: "유튜브",
        to: "/course/youtube",
      },
    ],
  });
  const isMobile = useBaseStore((state) => state.isMobile);
  const title = useBaseStore((state) => state.title);
  const user = useUserStore((state) => state.user);

  const onHandleDrawer = () => {
    useBaseStore.setState({ isDrawer: true });
  };

  const onHandleTextBtn = (title: string | undefined) => {
    FirebaseLogger("click", { event: `헤더-${title}` });
  };

  const onHandleSpace = () => {
    FirebaseLogger("click", { event: `헤더-임팩트스페이스` });
  };

  return (
    <>
      <NavigationDrawer />
      <StyledRoot>
        <StyledAppBar>
          <StyledToolbar>
            <Box>
              <StyledMenuIconBtn onClick={onHandleDrawer}>
                <Menu />
              </StyledMenuIconBtn>
              <StyledLogo
                onClick={() => {
                  onHandleTextBtn("/");
                }}
              >
                <Link to="/">
                  <Logo />
                </Link>
              </StyledLogo>
              <StyledToolbarTitleWrap>
                {state.categories.map((item) => {
                  return (
                    <Link to={item.to}>
                      <StyledTitle
                        variant="h6"
                        isActive={title === item.to}
                        onClick={() => {
                          onHandleTextBtn(item.to);
                        }}
                      >
                        {item.title}
                      </StyledTitle>
                    </Link>
                  );
                })}
              </StyledToolbarTitleWrap>
              <StyledToolbarRightWrap>
                <Link to={user ? "/space" : "/login"}>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    aria-label="스페이스로 이동"
                    role="navigation"
                    onClick={onHandleSpace}
                    startIcon={
                      <Image
                        className="float-animation"
                        src={rocket}
                        alt="스페이스로 이동"
                      />
                    }
                  >
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color="primary"
                      fontWeight={700}
                    >
                      스페이스로 이동
                    </Typography>
                  </Button>
                </Link>
              </StyledToolbarRightWrap>
              {isMobile && (
                <StyledRightBtn>
                  <Link to={user ? "/space" : "/login"}>
                    <Button
                      color="error"
                      size="small"
                      aria-label="스페이스로 이동"
                      role="navigation"
                      onClick={onHandleSpace}
                    >
                      <Typography
                        component="p"
                        variant="caption"
                        fontWeight={600}
                      >
                        스페이스로 이동
                      </Typography>
                    </Button>
                  </Link>
                </StyledRightBtn>
              )}
            </Box>
          </StyledToolbar>
        </StyledAppBar>
      </StyledRoot>
    </>
  );
};

// AppBar 스타일
const StyledRoot = styled(Box)(({ theme }) => ({
  zIndex: 9999,
  background: theme.palette.primary.main,
  width: "100%",
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  color: theme.palette.primary.main,
  boxShadow: "none",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  width: "100%",
  maxWidth: 1200,
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
}));

const StyledLogo = styled(Box)(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  top: 5,
  width: 125,
  [theme.breakpoints.down("md")]: {
    top: 0,
    textAlign: "center",
    position: "relative",
    width: "100%",
    margin: "0 auto",
  },
  "& img": {
    cursor: "pointer",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
  },
}));

const StyledMenuIconBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: 5,
  top: 5,
  display: "none",
  zIndex: 9999,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("lg")]: { display: "none" },
  [theme.breakpoints.down("md")]: { display: "block" },
}));

const StyledToolbarTitleWrap = styled(Box)(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  top: -15,
  left: 15,
  fontWeight: 500,
  [theme.breakpoints.down("md")]: { display: "none" },
}));

const StyledToolbarRightWrap = styled(Box)(({ theme }) => ({
  ...floatingAnimation,
  position: "absolute",
  top: 17,
  right: 80,
  fontWeight: 500,
  "& button img": {
    position: "relative",
    top: 5,
    width: 20,
    height: "auto",
  },
  [theme.breakpoints.down("md")]: { display: "none" },
}));

const StyledRightBtn = styled(IconButton)(({ theme }) => ({
  ...floatingAnimation,
  position: "absolute",
  zIndex: 9999,
  right: 5,
  top: 2,
  "& button": {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
  [theme.breakpoints.down("lg")]: { display: "none" },
  [theme.breakpoints.down("md")]: { display: "block" },
}));

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  margin: "0 15px",
  fontWeight: 600,
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  color: isActive ? theme.palette.error.main : theme.palette.common.black,
  "&:hover": {
    fontWeight: 700,
  },
  "&:after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    transform: "scaleX(0)",
    height: 2,
    backgroundColor: theme.palette.error.dark,
    transformOrigin: "bottom right",
    transition: "transform 0.3s ease-out",
  },
  "&:hover:after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));

// DUMP for shouldForwardProp
// const DataWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'minHeight' && prop !== 'paddingBottom'})<{ minHeight?: string; paddingBottom?: number }>( ({ theme, minHeight, paddingBottom }) => ({ position: 'relative', background: theme.palette.mode !== 'dark' ? theme.palette.grey[100] : theme.palette.secondary.light }));

export default Header;
