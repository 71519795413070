import { sweetAlert } from "./utils";
import theme from "~/styles/theme";

// 현재 버전
export const version = "1.0.241115a";

// PDF 뷰어 script
export const PDF_VIEWER_PREFIX =
  "https://mozilla.github.io/pdf.js/web/viewer.html?file=";

// OCR Engine
export const OCR_URI =
  "https://cdn.jsdelivr.net/npm/tesseract.js@5/dist/tesseract.min.js";

// 카카오 SDK
export const KAKAO_SDK_KEY = "kakao-key";
export const KAKAO_SDK_URI = "https://developers.kakao.com/sdk/js/kakao.min.js";

// 네이버 SDK
export const NAVER_AUTH_SDK_KEY = "naver-auth-key";
export const NAVER_AUTH_SDK_URI =
  "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";

// 저작권 양식
export const copyrightType = {
  "0": "BY(저작자 표시)",
  "1": "BY-ND(저작자 표시, 변경 금지)",
  "2": "BY-SA(저작자 표시, 동일조건 변경 허락)",
  "3": "BY-NC(저작자 표시, 비영리)",
  "4": "BY-NC-ND(저작자 표시, 비영리, 변경금지)",
  "5": "BY-NC-SA(저작자 표시, 비영리, 동일조건 변경 허락)",
};

// URL malware 검사 타입
export const uriLockType = {
  malware: "멀웨어 링크로 확인되어 입력이 불가해요.",
  advertise: "광고성 내용으로 판별되어 입력이 불가해요.",
  adultImage: "성인 이미지로 판별되어 입력이 불가해요.",
};

// API 기반 NSFW className 종류
export const nsfwClassName = ["Neutral", "Drawing", "Hentai", "Porn", "Sexy"];

// API 기반 Slang Filter
export const slangClassName = [
  "여성/가족",
  "남성",
  "성소수자",
  "인종/국적",
  "연령",
  "지역",
  "종교",
  "기타 혐오",
  "악플/욕설",
  "clean",
];

/**
 * 기본 알럿
 * @param {string} html - 제목 텍스트 (html)
 * @param {string} footer - 푸터 텍스트 (html)
 * @param {string} confirmButtonText - 완료 버튼 텍스트
 * @param {string} cancelButtonText - 닫기 버튼 텍스트
 * @param {Theme} theme - mui styles theme
 */
type AlertType = {
  html: string;
  footer?: string;
  confirmButtonText: string;
  cancelButtonText: string;
};

export const defaultAlert = async (
  alertData: AlertType,
  showCancelButton: boolean = true
) => {
  const { html, footer, confirmButtonText, cancelButtonText } = alertData;
  const confirmAlert = await sweetAlert.fire({
    html,
    footer: footer || "",
    cancelButtonText,
    confirmButtonText,
    showCancelButton,
    color: theme.palette.secondary.main,
    background: theme.palette.primary.main,
    confirmButtonColor: theme.palette.error.main,
    cancelButtonColor: theme.palette.secondary.main,
  });
  return confirmAlert;
};

type DeleteAlertType = {
  title?: string;
  footer?: string;
  typing?: string;
};

// 삭제 확인 기본 얼럿
export const deleteAlert = async ({
  title = null,
  footer = null,
  typing = "삭제",
}: DeleteAlertType) => {
  const confirmAlert = await sweetAlert.fire({
    html:
      title ||
      `<h1>스페이스를 삭제할까요?<br/><p>"삭제"라고 입력해 주세요.</p></h1>`,
    footer: footer || `<p>❗이 작업은 되돌릴 수 없어요.</p>`,
    cancelButtonText: "닫기",
    confirmButtonText: typing || "삭제",
    showCancelButton: true,
    color: theme.palette.secondary.main,
    background: theme.palette.primary.main,
    confirmButtonColor: theme.palette.error.main,
    cancelButtonColor: theme.palette.secondary.main,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    preConfirm: async (pin) => {
      if (!pin || pin != (typing || "삭제"))
        return sweetAlert.showValidationMessage(
          `"${typing || "삭제"}"라고 기입해주세요.`
        );
    },
    allowOutsideClick: () => !sweetAlert.isLoading(),
  });
  if (!confirmAlert.isConfirmed) {
    return "none";
  }
  return confirmAlert?.value || "";
};

// NOTE:
// For Firebase EmailLink Sign-In
export const actionCodeSettings = {
  url: "https://impactus.kr/login",
  handleCodeInApp: true, // This must be true
  iOS: {
    bundleId: "kr.impactus.app",
  },
  android: {
    packageName: "kr.impactus.app",
    installApp: true,
    minimumVersion: "12",
  },
  dynamicLinkDomain: "",
};

// 로컬 스토리지
export const LOCAL_STORAGE_ENUM = {
  BOT_CHECK: "@impactus/botcheck", // 봇 체크
  PAYMENT: "@impactus/payment", // 결제 정보
  USER_IP_COUNTRY: "@impactus/user-ip", // 아이피 및 국가 정보 체크
  SPACE_AUTH_METHOD: "@impactus/auth/method",
  SPACE_LIST_VIEW: "@impactus/space/is-grid-view", // 스페이스 보기 모드 수정
  SPACE_INIT_USER: "@impactus/space/user", // 회원가입 시 유저 정보
  SPACE_ENTER_INPUT_RETRY: "@impactus/space/enter", // 스페이스 재입장 상태 - 입력값 반복
  SPACE_ENTER_STATUS_RETRY: "@impactus/space/status", // 스페이스 재입장 상태 - 반복 중지 상태
  SPACE_CARD_READY: "@impactus/space/card-ready", // 스페이스 상태
  SPACE_CARD_RETRY_LIMIT: 2000, // 카드 재생성 시간
  SPACE_PIN_MODE_PREFIX: "@impactus/space/card-pin-mode/", // 스페이스별 카드 이동 금지
  SPACE_IS_SHOW_BOARD_PREFIX: "@impactus/space/board/", // 스페이스별 > 보드별 접기 상태 저장
  SPACE_MEMBERSHIP_INPUT_RETRY: "@impactus/space/membership/retry",
  SPACE_MEMBERSHIP_STATUS_RETRY: "@impactus/space/membership/status",
  TASK_MEMBER: "@impactus/space/classroom/member", // 스페이스 > 과제 평가 > 구성원 정보
};

// EMOJI
// ✔︎, ➡️, 👉, 👍, 👎, 🙌, 👋, 🎯, 😠, 🧐, 😉, 🤔, 🐥, ✨, 🔑, ⭐️, 🎤, 🍩, 💌, 🏆, 🥈, 🥉, 💡, ✏️, 🇰🇷
