import { AlertColor } from "@mui/material";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { DeviceType } from "~/types/Base";

type AlertNotifyType = {
  message: string;
  option?: { vertical: string; horizontal: string };
  timeout?: number;
  type?: AlertColor;
  onClick?: () => void;
};

const store = () => ({
  isReady: false as boolean, // 홈 로딩 초기화 완료 여부
  isMobile: false as boolean, // 모바일 여부 (해상도 width 기반)
  isTablet: false as boolean, // 태블릿 여부 (해상도 width 기반)
  isMobileApp: false as boolean, // 모바일 앱 여부
  isDarkMode: false as boolean, // 다크모드 여부
  isLandscape: false as boolean, // 랜드스케이프 여부
  isStandalone: false as boolean, // standalone 여부(for PWA)
  isFullscreen: false, // 풀스크린 여부
  isBottomTab: true, // 모바일(바톰 내비게이션 여부)
  loadingTitle:
    "데이터를 안전하게 처리 중이에요.<br/>잠시만 기다려 주세요." as string, // 로딩 전용 타이틀
  networkState: "" as string, // 네트워크 상태
  language: "ko" as string, // 언어 설정
  isDrawer: false as boolean, // 랜딩페이지 모바일 드로워 여부
  title: "" as string, // location.pathname
  device: null as DeviceType, // 디바이스 정보
  isLoading: false as boolean, // 전체 데이터 로딩
  localStorageUsage: {
    percentageUsed: 0, // 사용량 %
    remaining: 0, // 남은 용량 Bytes
  }, // 사용 가능한 로컬 스토리지 용량 계산
  notify: {
    message: "",
    option: { vertical: "top", horizontal: "center" },
    timeout: 4000,
    type: "error",
  } as AlertNotifyType, // 앱 시스템 알림
  alert: "" as string,
});

const useBaseStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useBaseStore;
