import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { User } from "firebase/auth"; // UserCredential, AuthCredential
import type { TypeOfUser } from "~/types";

const store = () => ({
  user: null as TypeOfUser.User, // 유저 DB
  userAuth: null as User, // Firebase Auth 객체 정보
  isCertify: false as boolean, // 이메일 로그인 시 이메일 인증되었는지
  isSignIn: false as boolean, // 로그인 여부 체크
  isFirst: false as boolean, // 최초 로그인 여부
  isAnonymous: false as boolean, // 익명 로그인 여부

  // Sign-In & Sign-Up 관련
  isFindPasswordOpen: false as boolean, // 비밀번호 재설정 오픈
  isSignUpOpen: false as boolean, // 회원가입 오픈

  // ReCaptcha
  formRecaptchaRefresh: false as boolean, // 리캡차 리프레쉬
  reCaptchaToken: "" as string, // 리캡차 토큰

  // FCM
  messaging: {
    supported: false,
    show: false,
    title: "",
    body: "",
    data: null,
  } as any,
});

const useUserStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useUserStore;
