import { TypeOfSpace } from "~/types";
import { useBaseStore, useSpaceStore, useUserStore } from "~/libs/store";
import { getUIDwithRange, getPinCharacterCode } from "~/libs/utils";
import { spacePinCodeCreate, spaceUpdateWithHistory } from "~/libs/db/space";
import {
  write,
  form,
  goodReaction,
  likeReaction,
  starReaction,
  thinkingReaction,
} from "~/assets";
import { getUserProfile } from "~/libs/ga";
import { TypeOfUser } from "~/types";
import { solomonAPI } from "~/libs/api";
import { isAnonymousUser } from "~/libs/permissions";

// NOTE: 스페이스 꾸밈용 수식어 형용사 목록
export const goodAdjectiveList = [
  "고운",
  "멋진",
  "뛰어난",
  "밝은",
  "아름다운",
  "푸른",
  "희망찬",
  "힘찬",
  "지혜로운",
  "유능한",
  "위대한",
  "감성있는",
  "빛나는",
  "휘황찬란한",
  "창의적인",
  "열정적인",
  "다정한",
  "느긋한",
  "사교적인",
  "사려깊은",
  "현명한",
];

// 스페이스 설정 / true: 가능, false: 불가능
export const defaultSpaceSettings = {
  // 보드&카드 관련
  allowAddColumn: false, // 컬럼 추가 가능 여부
  disableColumnDrag: false, // 컬럼 드래그 가능 여부
  allowRemoveColumn: false, // 컬럼 삭제 가능 여부
  allowRenameColumn: false, // 컬럼 이름 변경 가능 여부
  disableCardDrag: false, // 카드 드래그 가능 여부
  allowRemoveCard: false, // 카드 삭제 가능 여부
  allowAddCard: true, // 카드 추가 가능 여부
  // 카드 내의 기능 관련
  allowCardReaction: true, // 카드에 반응 추가 가능 여부
  allowCardMember: true, // 카드에 멤버 추가 가능 여부
  allowCardLabels: true, // 카드에 라벨 추가 가능 여부
  allowCardEditing: true, // 카드 에디팅 가능 여부
  allowCardColor: true, // 카드 색상 변경 가능 여부
  // 스타일 관련
  allowSpaceStyle: true, // 스페이스 스타일 수정 가능 여부
  allowBoardStyle: true, // 보드 스타일 수정 가능 여부
  allowCardStyle: true, // 카드에 스타일 수정 가능 여부
  allowTimeStyle: true, // 보드/카드 타임스탬프 표시 여부
  // 부가기능 관련
  detectionImage: false, // 유해 이미지 방지 체크
  detectionLink: false, // 유해 링크 방지 체크
  detectionSlang: false, // 욕설 방지 체크

  allowAnalytics: false, // 키워드, 이미지 등 분석 기능
  allowDashboard: false, // 대시보드 사용 가능
  allowStructure: false, // 구조 변경 사용 가능(리스트, 테이블, 맵, 앨범 형태로 보기 등)

  allowAnonym: true, // 보드/카드/채팅 익명 표시 여부
  allowCardCopy: false, // 카드 템플릿으로 복제 가능 여부
  allowSpaceCopy: false, // 스페이스 템플릿으로 복제 가능 여부
  allowSpaceSlide: false, // 스페이스 슬라이드 사용 여부
  allowSpaceChat: false, // 스페이스 채팅 가능 여부
  allowSpaceEditCoy: true, // 스페이스 내에서 복사/붙여넣기 가능 여부
  // anonymSpaceChat: false, // 스페이스 익명 채팅 여부

  allowCardNotify: true, // 스페이스 카드 댓글 알림 설정
} as TypeOfSpace.SpaceSettings;

// 스페이스 스타일 기본값
export const defaultSpaceStyle = {
  background: {}, // 백그라운드 이미지
  theme: "default", // 다크, 라이트, 시스템 등
  fontFamily: "default", // 폰트 패밀리
  fontSize: "default", // small, medium, large
  bgColor: "default", // 백그라운드 컬러
  color: "default", // 컬러
  timestamp: "default", // 타임스탬프
};

// 스페이스 반응 기본값
export const defaultSpaceReaction = [
  {
    title: "좋아요",
  },
  {
    title: "잘했어요",
  },
  {
    title: "고민해볼게요",
  },
  {
    title: "멋져요",
  },
] as TypeOfSpace.SpaceReaction[];

// 스페이스 반응별 이모지
export const defaultSpaceReactionEmoji = {
  멋져요: goodReaction,
  고민해볼게요: thinkingReaction,
  잘했어요: starReaction,
  좋아요: likeReaction,
};

// 스페이스, 카드 변경 타입 한글화로 변경하기 위한 변수
export const defaultSpaceUpdateTypeChooseForKor = {
  "rename-board": "보드의 이름을 수정했어요.",
  "move-board": "보드를 이동했어요.",
  "delete-board": "보드를 삭제했어요.",
  "move-card": "카드를 이동했어요",
  "rename-card": "카드 이름을 수정했어요.",
  "delete-card": "카드를 삭제했어요.",
};

// 배경색 기본
export const bgColorList = [
  "#ffffff",
  "#ffdada",
  "#fff4ce",
  "#deffdd",
  "#bbeaff",
  "#eed8ff",
];

// 배경색 기본 한글화
export const bgColorListTitle = [
  "기본",
  "빨강",
  "노랑",
  "초록",
  "파랑",
  "보라",
];

// 카드 배경색 기본 한글화
export const bgColorObjectList = [
  { bgColor: "#ffffff", title: "기본" },
  { bgColor: "#ffdada", title: "빨강" },
  { bgColor: "#fff4ce", title: "노랑" },
  { bgColor: "#deffdd", title: "초록" },
  { bgColor: "#bbeaff", title: "파랑" },
  { bgColor: "#eed8ff", title: "보라" },
] as { bgColor: string; title: string }[];

export type bgColorType = { bgColor: string; title: string };

// 새로 스페이스 만들 시에 생성되는 형태
export const initBoardColumns = () => {
  const uuidList = getUIDwithRange(4);
  const user = useUserStore.getState().user;
  const timestamp = new Date().getTime();

  // TODO: 메모형, 폼, 팀, 미디어 보드 노출하기
  return [
    {
      title: "메모형 보드",
      id: uuidList[0],
      uid: user.uid,
      email: user.email,
      lastUpdate: timestamp,
      create: timestamp,
      graphic: "default",
      ver: 2,
      cards: [
        // { id: uuidList[1], title: "새 카드", uid: user.uid, email: user.email, isLock: true, lastUpdate: timestamp, create: timestamp, ver: 2 },
      ],
    },
    {
      title: "질문형 보드",
      id: uuidList[2],
      uid: user.uid,
      email: user.email,
      lastUpdate: timestamp,
      create: timestamp,
      graphic: "form",
      ver: 2,
      cards: [],
    },
  ];
};

/**
 * 스페이스 내에서 컬럼 만들 시에 만들어지는 형태
 * - graphic 타입은 부모 함수에서 전달
 */
export const defaultBoardColumns = () => {
  const uuid = getUIDwithRange(1);
  const profile = getUserProfile();
  const timestamp = new Date().getTime();
  return {
    id: uuid[0],
    title: "새 보드",
    uid: profile.uid,
    email: profile.email,
    cards: [],
    lastUpdate: timestamp,
    create: timestamp,
    ver: 2,
  };
};

/**
 * 스페이스 내에서 카드 만들 시에 만들어지는 형태
 */
export const defaultBoardCard = () => {
  const uuid = getUIDwithRange(1);
  const space = useSpaceStore.getState().selectedSpace;
  const profile = getUserProfile();
  const timestamp = new Date().getTime();

  return {
    id: uuid[0],
    uid: profile.uid,
    sid: space.id,
    email: profile.email,
    isLock: true,
    lastUpdate: timestamp,
    create: timestamp,
    ver: 2,
  };
};

// 스페이스 핀코드 생성
export const defaultPinCharacterCode = async (uid: string) => {
  let ret = "";
  while (true) {
    const pinCode = getPinCharacterCode();
    const checkPinCode = await spacePinCodeCreate(pinCode, uid);
    if (checkPinCode) {
      ret = pinCode;
      break;
    }
  }
  return ret;
};

// 보드 사용량 계산
export const checkColumnsUsage = (
  columns: TypeOfSpace.SpaceColumnsBoard[]
): { columns: number; cards: number } => {
  let cardUsage = 0;
  columns?.forEach((column) => {
    cardUsage += column?.cards?.length || 0;
  });

  return {
    columns: columns?.length || 0,
    cards: cardUsage,
  };
};

// 스페이스 제한 타입
export type ISpaceLimit = {
  [key: string]: {
    MAX_SPACE: number; // 최대 스페이스 수
    MAX_BOARD: number; // 최대 보드 수
    MAX_CARD: number; // 최대 카드 수
    MAX_USER: number; // 최대 유저 수
    MAX_STORAGE: number; // 최대 스토리지 수
  };
};

/**
 * 티어별 최대치
 *  - 스토리지는 byte 단위로 체크
 */
export const SPACE_LIMIT = {
  BASIC: {
    MAX_SPACE: 3,
    MAX_BOARD: 10,
    MAX_CARD: 300,
    MAX_USER: 50,
    MAX_STORAGE: 10 * Math.pow(1024, 2), // 10MB
  },
  PRO: {
    MAX_SPACE: 20,
    MAX_BOARD: 15,
    MAX_CARD: 500,
    MAX_USER: 100,
    MAX_STORAGE: 500 * Math.pow(1024, 2), // 500MB
  },
  ENTERPRISE: {
    MAX_SPACE: 100,
    MAX_BOARD: 40,
    MAX_CARD: 1000,
    MAX_USER: 300,
    MAX_STORAGE: 3 * Math.pow(1024, 3), // 3GB
  },
};

/**
 * 티어별 가격 정책 및 추가 이용 요금
 * - 더 구상해봐야 함(체계적으로!)
 */
export const SPACE_PRICING = {
  BASIC: {
    PRICING_MONTH: 0,
    PRICING_ANNUAL: 0,
  },
  PRO: {
    PRICING_MONTH: 10000 * 1,
    PRICING_ANNUAL: 8000 * 12,
  },
  ENTERPRISE: {
    PRICING_MONTH: 12000 * 1,
    PRICING_ANNUAL: 10000 * 12,
  },
};

/**
 * 보드 그래픽 타입 정보
 */
export const BOARD_GRAPHIC = {
  default: {
    text: "메모형",
    image: write,
    type: "default",
    isActive: true,
    info: {
      title: "💡 메모형 보드",
      content: "개개인이 자유롭게 내용을<br/>작성할 수 있어요.",
    },
  },
  form: {
    text: "질문형",
    image: form,
    type: "form",
    isActive: true,
    info: {
      title: "💡 질문형 보드",
      content: "정해진 질문에 맞춰 답변을<br/>작성할 수 있어요.",
    },
  },
};

// surveyJS 폼 결과 name, title, value 함께 변환하기
export const getFormResult = (
  done: { [key: string]: any },
  formSurveyReal: any[]
) => {
  const result = {} as any;
  for (const pages of formSurveyReal) {
    pages.elements.map((ele: any) => {
      for (const key in done) {
        const val = done[key];
        if (ele?.name === key) {
          result[key] = {
            name: ele?.name || "",
            title: ele?.title || "",
            val,
          };
        }
      }
    });
  }
  return result;
};

/**
 * 스페이스 생성 시 허용량 제한을 위한 티어 체크
 * @param {TypeOfUser.User} user - 유저 정보
 * @param {string} checkType - "space" | "board" | "card" = "space"
 * @returns
 */
export const checkUserTier = (
  user: TypeOfUser.User,
  checkType: "space" | "board" | "card" = "space"
): { status: "success" | "failure"; msg: string } => {
  const type = user?.type || 0; // 타입(관리자 여부 판단)
  const tier = user?.tier || "BASIC"; // 결제티어
  const usage = user?.usage?.space || 0; // 스페이스 생성 사용량 판단

  // 관리자
  if (type === -1) {
    return { status: "success", msg: "" };
  }

  // 스페이스 생성 제한 제한 갯수 계산
  const findLimit = Object.keys(SPACE_LIMIT).find(
    (limit) => limit === tier.toLocaleUpperCase()
  );

  if (checkType === "space") {
    if (usage >= SPACE_LIMIT[findLimit].MAX_SPACE) {
      return {
        status: "failure",
        msg: `<strong>${tier}</strong> 버전에서는 <strong>최대 ${SPACE_LIMIT[findLimit].MAX_SPACE}개</strong>의 스페이스만 생성할 수 있어요`,
      };
    } else {
      return { status: "success", msg: "" };
    }
  }
  if (checkType === "board") {
    if (usage >= SPACE_LIMIT[findLimit].MAX_BOARD) {
      return {
        status: "failure",
        msg: `<strong>${tier}</strong> 버전에서는 <strong>최대 ${SPACE_LIMIT[findLimit].MAX_BOARD}개</strong>의 스페이스만 생성할 수 있어요`,
      };
    } else {
      return { status: "success", msg: "" };
    }
  }
  if (checkType === "card") {
    if (usage >= SPACE_LIMIT[findLimit].MAX_CARD) {
      return {
        status: "failure",
        msg: `<strong>${tier}</strong> 버전에서는 <strong>최대 ${SPACE_LIMIT[findLimit].MAX_CARD}개</strong>의 스페이스만 생성할 수 있어요`,
      };
    } else {
      return { status: "success", msg: "" };
    }
  }
};

/**
 * 유저 허용량 체크
 * @param {string} tier - tier 정보 (basic, pro, enterprise)
 * @param {TypeOfUser.UserPermissionType} type -
 * @returns
 */
export const checkUserAllowUsage = (
  tier: string,
  type: TypeOfUser.UserPermissionType = 0
) => {
  if (type === -1) {
    return SPACE_LIMIT?.["ENTERPRISE"];
  }

  const findLimit = Object.keys(SPACE_LIMIT).find(
    (limit) => limit === tier.toLocaleUpperCase()
  );
  return SPACE_LIMIT?.[findLimit];
};

/**
 * 현재 스페이스 볼륨 사용량 계산용 함수
 * @param {TypeOfSpace.SpaceColumnsBoard[]} spaceColumns - 보드 및 카드 정보 모음
 * @returns
 */
export const checkSpaceVolumeUsage = (
  spaceColumns: TypeOfSpace.SpaceColumnsBoard[]
) => {
  let usage = 0;
  if (spaceColumns?.length > 0) {
    spaceColumns.forEach((col) => {
      if (col?.cards?.length > 0) {
        col.cards.forEach((card: TypeOfSpace.SpaceColumnsCard) => {
          if (card?.attaches?.length > 0) {
            card.attaches.forEach((attach: TypeOfSpace.SpaceCardAttach) => {
              if (
                attach.type?.includes("image") ||
                attach.type?.includes("video") ||
                attach.type?.includes("file")
              ) {
                usage += attach.size;
              }
            });
          }
        });
      }
    });
  }
  const selectedSpaceUsage = useSpaceStore.getState().selectedSpaceUsage;
  if (selectedSpaceUsage?.length > 0) {
    selectedSpaceUsage.forEach((u) => {
      usage += u?.size || 0;
    });
  }

  return usage;
};

/**
 * 스페이스 기능 클릭 시 URL replace
 * @param {string} link - 링크 정보
 * @param {string} title - 페이지 제목
 * @param {boolean} isPush - history stack 처리 방식
 */
export const URLHistoryUpdate = async (
  link: string,
  title: string,
  isPush: boolean = false
) => {
  const isAnonymous = isAnonymousUser();
  const customLink = isAnonymous
    ? link.replace("/space/board/", "/share/space/board/")
    : link;
  if (isPush) {
    history.pushState({}, ``, customLink);
  } else {
    history.replaceState({}, ``, customLink);
  }
  document.title = title || "임팩트 스페이스";
};

interface SpaceHistoryProfile {
  sid: string;
  uid: string;
  type: TypeOfSpace.SpaceHistoryType;
  content: string;
  bid?: string;
  cid?: string;
  newer?: any;
  original?: any;
  ver?: number;
}

/**
 * 히스토리 저장용 객체 내보내기
 * @param {SpaceHistoryProfile} history 표현 객체
 * @returns {SpaceHistoryProfile} 히스토리 객체 리턴
 */
export const getSpaceHistoryProfile = (props: SpaceHistoryProfile) => {
  const timestamp = new Date().getTime();
  const uuid = getUIDwithRange(1);
  const history = {
    id: uuid[0],
    sid: props?.sid || null,
    uid: props?.uid || null,
    bid: props?.bid || null,
    cid: props?.cid || null,
    newer: props?.newer || null,
    original: props?.original || null,
    type: props.type,
    content: props.content,
    create: timestamp,
    ver: props?.ver || 2,
  };
  return history;
};

/**
 * 스페이스 멤버 프로필 타입
 */
export interface SpaceMemberProfile {
  uid: string;
  email: string;
  thumbnail: string;
  name: string;
  personalId: string;
  displayName: string;
  type: TypeOfSpace.SpacePermission;
}

/**
 * uid로 스페이스 멤버 프로필 가져오기
 * @param {string} uid
 * @returns
 */
export const getSpaceMemberProfile = (
  uid: string
  // publisher: any = null
): SpaceMemberProfile => {
  const emptyUser = {
    uid: "",
    email: "",
    thumbnail: "",
    name: "",
    personalId: "",
    displayName: "",
    type: "Unknown",
  } as SpaceMemberProfile;

  const space = useSpaceStore.getState().selectedSpace;
  if (!space || !space?.permissionUser) {
    return emptyUser;
  }
  const findUserProfile = space?.permissionUser?.find?.((p) => p?.uid === uid);
  if (!findUserProfile) {
    return emptyUser;
  }
  return findUserProfile as SpaceMemberProfile;
};

/**
 * 스페이스별 전체 구성원 프로필 새로고침
 */
export const getSpaceMemberProfileReloading = async () => {
  let space = useSpaceStore.getState().selectedSpace;
  const uid = getUserProfile().uid;
  if (!space || !space?.permissionUser) {
    return null;
  }
  const profiles = space?.permissionUser?.map((user) => user.uid);
  const apiRes = await solomonAPI.userProfiles(profiles);

  if (apiRes.status !== "success") {
    useBaseStore.setState({
      notify: {
        message: "서버와 통신할 수 없어요.<br/>잠시 후에 다시 시도해 주세요.",
      },
    });
    return;
  }
  const newPermissionUser = [];
  apiRes.result?.forEach((newProfile) => {
    let type = "editor";
    if (newProfile?.type === -1 || space?.uid === newProfile?.uid) {
      type = "owner";
    }
    newPermissionUser.push({
      displayName: newProfile?.displayName || "",
      uid: newProfile?.uid || "",
      email: newProfile?.email || "",
      thumbnail: newProfile?.profileImage || "",
      name: newProfile?.name || "",
      personalId: newProfile?.personalId || "",
      type,
    });
  });
  if (newPermissionUser?.length <= 0) {
    useBaseStore.setState({
      notify: {
        message:
          "정보를 업데이트 하는데 오류가 있어요.<br/>잠시 후에 다시 시도해 주세요.",
      },
    });
    return;
  }
  const history = getSpaceHistoryProfile({
    sid: space.id,
    uid,
    type: "update" as TypeOfSpace.SpaceHistoryType,
    content: "renewal-user",
    newer: newPermissionUser,
    original: space?.permissionUser,
  });
  space = { ...space, permissionUser: newPermissionUser };
  await spaceUpdateWithHistory(space.id, space, history);
  useBaseStore.setState({
    notify: { message: "참여자 프로필이 수정됐어요.", type: "success" },
  });
};

/**
 * Firestore 기반 카드 ID로 보드, 카드 조회
 * @param {string} cardId - 카드 id
 * @returns 보드, 카드
 */
export const getColumnsCardById = (cardId: string) => {
  const spaceColumns = useSpaceStore.getState()?.selectedSpaceColumns;
  let colBoard = null;
  let colCard = null;
  spaceColumns?.forEach?.((columns) => {
    columns?.cards?.forEach((card: TypeOfSpace.SpaceColumnsCard) => {
      if (card?.id === cardId) {
        colBoard = columns;
        colCard = card;
        return false;
      }
    });
  });
  return { colBoard, colCard };
};

/**
 * RTDB 기반 보드&카드 index 조회
 * @param {string} bid - 보드 id
 * @param {string} cid - 카드 id
 * @returns 보드, 카드 Index
 */
export const getColumnsIndexById = (bid: string, cid: string) => {
  const spaceColumns = useSpaceStore.getState()?.selectedSpaceColumns;
  const boardId = spaceColumns.findIndex((col) => col?.id === bid);
  const cardId = spaceColumns?.[boardId]?.cards?.findIndex(
    (c: TypeOfSpace.SpaceColumnsCard) => c?.id === cid
  );
  return { boardId, cardId };
};

/**
 * RTDB 기반 보드 index 조회
 * @param {string} bid - 보드 id
 * @returns 보드 index 조회
 */
export const getColumnsIndex = (bid: string) => {
  const spaceColumns = useSpaceStore.getState()?.selectedSpaceColumns;
  const boardIndex = spaceColumns.findIndex((col) => col?.id === bid);
  return boardIndex;
};

/**
 * 보드 설정에서 그룹원 간 작성 가능으로 처리한 경우, 같은 그룹원인지 체크
 * @param {string} ownUID - 카드 소유자 uid
 * @param {string} memberUID - 체크 멤버 uid
 * @returns {boolean} - 그룹여부
 */
export const isInGroupUser = (ownUID: string, memberUID: string): boolean => {
  const space = useSpaceStore.getState().selectedSpace;
  if (!space || space?.groups?.length <= 0) return false;

  let isGroup = false;
  space?.groups?.forEach((group) => {
    // owner 그룹 찾기
    const findOwner = group?.member?.find((member) => member === ownUID);
    if (findOwner) {
      // owner 그룹에 같은 조로 포함되어 있는지 체크
      const findMember = group?.member?.find((member) => member === memberUID);
      if (findMember) {
        isGroup = true;
      }
      return false;
    }
  });

  return isGroup;
};
